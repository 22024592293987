// COMPONENTS PAGE
if ($('.s-listComponents').length) {
    var cptPage = {
        component__content: $('.c-component__content')
    };

    // sticky sidebar
    $(window).on('scroll', function () {stickyPosition('.s-listComponents__aside', 10, get__navHeight() + 10);});
    $(window).on('load', function () {stickyPosition('.s-listComponents__aside', 10, get__navHeight() + 10);});

    // aside scroll links
    $('.js-asideScrollLink').on('click', function(e) {
        e.preventDefault();
        var finalDestiny = $(this).attr('href');
        goToSection__scroll(finalDestiny, 90, 90, 700, 10);
    });

    // bg components - toggle menu
    $('.js-bgDark').on('click', function() {
        cptPage.component__content.addClass('u-bg-darkGray');
        cptPage.component__content.removeClass('u-bg-white');
        cptPage.component__content.removeClass('u-bg-white3');
    });
    $('.js-bgLight').on('click', function() {
        cptPage.component__content.removeClass('u-bg-darkGray');
        cptPage.component__content.addClass('u-bg-white');
        cptPage.component__content.removeClass('u-bg-white3');
    });
    $('.js-bgNone').on('click', function() {
        cptPage.component__content.removeClass('u-bg-darkGray');
        cptPage.component__content.removeClass('u-bg-white');
        cptPage.component__content.addClass('u-bg-white3');
    });

    // show observations icon
    $('.c-component__observations').each(function() {
        var thisText = $(this).html();
        if (thisText !== '') {
            $(this).closest('.c-component').find('.option__obs').removeClass('u-dnone');
        }
    });

    // show observations on click
    $('.js-openObs').on('click', function() {
       $(this).closest('.c-component').toggleClass('is-obs-active');
    });

    // copy html
    $('.js-copyCode').on('click', function () {
        // get the html
        var thisHTML = $(this)
            .closest('.c-component')
            .find('.c-component__hero')
            .html()
            .replace(/\s+/g, ' ')
            .trim();

        // analisa o codigo copiado e retira os SVG's de dentro
        var replaceSVG = '<?php include(\'media/img/icons/icon_ICONNAME.svg\'); ?>';
        var regexRemoveSVG = /<svg .*?<\/svg>/gi; //non-greedy
        var finalString = thisHTML.replace(regexRemoveSVG, replaceSVG);

        // set the html in val input
        var thisInput = $(this).closest('.c-component').find('.c-component__inputHidden');
        thisInput.val(finalString);
        thisInput.select();
        document.execCommand("copy");
    });
}

// forms
// -- masks
if (is.not.ie()) {
    $('.cepMask').mask("99999-999");
    $('.cpfMask').mask("999.999.999-99");
    $('.dataMask').mask("99/99/9999");
    $('.foneMask').focusout(function () {
        var phone, element;
        element = $(this);
        element.unmask();
        phone = element.val().replace(/\D/g, '');
        if (phone.length > 10) {
            element.mask("(99) 99999-999?9");
        } else {
            element.mask("(99) 9999-9999?9");
        }
    }).trigger('focusout');
}

// -- select 2
$('.js-select2__multiple').select2({
    placeholder: "Selecione",
});
$('.js-select2__oneOption').select2({
    placeholder: "Selecione",
});
$('.c-form__field.--select2 label *').on('click', function () {
    var idSelect2 = $(this).closest('li').find('select').attr('id');
    idSelect2 = '#' + idSelect2;
    $(idSelect2).select2('open');
});

// -- default form
if ($('.c-form').length) {
    // select
    $('.c-form__field.--select select').on('click', function () {
        $(this).closest('.c-form__field.--select').addClass('is-active');
    });
    $('.c-form__field.--select select').on('focusout', function () {
        var thisSelectOption = $(this).children('option:selected').text();
        $(this).closest('.c-form__field.--select').find('.inputBox').children('input').val(thisSelectOption);
        $(this).closest('.c-form__field.--select').removeClass('is-active');
    });

    // file
    $('.inputAttach').on('change', function() {
        var t = $(this).val().split('fakepath');
        console.log(t);
        $(this).closest('.liFile').find('.inputText').val(t[1]);
    });

    // rangeDefault
    var rangeSlider = function(){
        var slider = $('.rangeSlider'),
            range = $('.rangeSlider__range'),
            value = $('.rangeSlider__value');

        slider.each(function(){
            value.each(function(){
                var value = $(this).prev().attr('value');
                $(this).html(value);
            });
            range.on('input', function(){
                $(this).next(value).html(this.value);
            });
        });
    };
    rangeSlider();
}

// grids / tabs
if ($('.c-responsiveGrid').length) {

    function removeGridClass(el) {
        for (var i = 1; i <= 12; i++) {
            el.removeClass('--col' + i);
        }
        el.removeClass('--colNaN');
    }

    $(window).on('load resize', function() {
        $('.c-responsiveGrid').each(function( index ) {
            var propsGrid = {
                maxwBreaks: $(this).attr('data-maxw-breaks').split(', ').map(Number),
                maxwCols: $(this).attr('data-maxw-cols').split(', ').map(Number),
                initCols: parseInt($(this).attr('data-cols-init')),
                windowW: $(window).width(),
            };
            var qtfBreaks = propsGrid.maxwBreaks.length;
            if (propsGrid.maxwBreaks.length === propsGrid.maxwCols.length) {
                for (var i = 0; i <= qtfBreaks; i++) {
                    if (propsGrid.maxwBreaks[i] >= propsGrid.windowW) {
                        removeGridClass($(this));
                        $(this).addClass('--col' + propsGrid.maxwCols[i]);
                    }
                    if (propsGrid.windowW > propsGrid.maxwBreaks[0]) {
                        removeGridClass($(this));
                        $(this).addClass('--col' + propsGrid.initCols);
                    }
                }
            } else {
                console.log('Ta faltando media querie ou colunas, verifique seu componente');
            }
        });
    });
}
if ($('.c-tabHorizontal').length) {
    var propsTabH = {
        el: $('.c-tabHorizontal'),
        item: $('.c-tabHorizontal a'),
        header: $('.c-tabHorizontal__header'),
        content: $('.c-tabHorizontal__content'),
    };

    propsTabH.item.on('click', function(e) {
        e.preventDefault();
        // toggle active header
        $(this).closest('.c-tabHorizontal__header').find('a').removeClass('is-active');
        $(this).addClass('is-active');

        // scroll to active
        //console.log($(this).closest('.c-tabHorizontal__header ul').scrollLeft());

        // toggle active content
        var contentDestiny = $(this).attr('href');
        var thisElement = $(this);
        thisElement.closest(propsTabH.el).find(propsTabH.content).removeClass('is-active');
        setTimeout(function(){
            thisElement.closest(propsTabH.el).find(propsTabH.content).addClass('u-dnone');
            $(contentDestiny).removeClass('u-dnone');
            $(contentDestiny).addClass('is-active');
        }, 350);
    });
}
if ($('.c-toggleList').length) {
    $('.c-toggleList__header').on('click', function() {
        $(this).parent().toggleClass('is-active');
    });
}
if ($('.c-tabSwiper').length) {
    let tab = $('.c-tabSwiper__tabs a');

    tab.on('click', function(e) {
        $('.c-tabSwiper__tabs a').removeClass('is-active');
        $(this).addClass('is-active');
    });
}

// menus
if ($('.c-hamburguer').length) {
    $('.c-hamburguer').on('click', function() {
       if ($(this).hasClass('is-blocked')) {
       } else {
           $(this).toggleClass('is-active');
       }
    });
}
if ($('.c-smartMenu').length) {
    let cSmartMenu = $('.c-smartMenu');

    // IDENTIFY SUBMENUS
    cSmartMenu.each(function( index ) {
        cSmartMenu
            .children('ul').children('li').find('ul')
            .each(function( index ) {
                $(this).parent('li').children('a')
                    .addClass('js-subMenuToggle')
                    .addClass('--withSubMenu')
            });
    });

    // CLOSE SUB MENUS --mainHover
    cSmartMenu.children('ul').children('li').children('a').parent('li')
        .on('mouseleave', function() {
            if ($(this).closest('.c-smartMenu').hasClass('--mainHover')) {
                $(this).children('ul').removeClass('is-open');
            }
        });
    cSmartMenu.children('ul').children('li').children('ul')
        .on('mouseleave', function() {
            if ($(this).closest('.c-smartMenu').hasClass('--mainClick')) {
                $(this).removeClass('is-open');
                $(this).parent('li').children('a').removeClass('is-active');
            }
        });

    // OPEN ON MOUSEENTER
    cSmartMenu.children('ul').children('li').children('a')
        .on('mouseenter', function() {
            if ($(this).closest('.c-smartMenu').hasClass('--mainHover')) {
                console.log('mouseenter')
                $(this).closest('li').children('ul').addClass('is-open');
            }
        })

    $(document).on('click', '.js-subMenuToggle', function(e) {
        e.preventDefault();
        $(this).toggleClass('is-active');
        $(this).parent('li').children('ul').toggleClass('is-open')
    })
}
if ($('.c-favoriteToggle').length) {
    $('.c-favoriteToggle').on('click', function (){
        $(this).toggleClass('is-active');
        let thisCount = parseInt($(this).children('.c-favoriteToggle__count').text());
        if ($(this).hasClass('is-active')) {
            $(this).children('.c-favoriteToggle__count').text(thisCount + 1)
        } else {
            $(this).children('.c-favoriteToggle__count').text(thisCount)
        }
    })
}

// ui
if ($('.c-cookieAlert').length) {
    $(window).on('load', function() {
        $('.c-cookieAlert').addClass('is-active');
    });
    $('.js-closeCookieAlert').on('click', function() {
        $('.c-cookieAlert').removeClass('is-active');
    });
}

// cards
if ($('.c-card').length) {

}

// c-draggableRow
$(document).on('click', '.js-deleteRow', function() {
    let thisRow = $(this).closest('.c-draggableRow');
    Swal.fire({
        title: 'Tem certeza?',
        text: "Você realmente deseja excluir esta linha?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f05555',
        cancelButtonColor: '#1ca33d',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Excluir.'
    }).then((result) => {
        if (result.isConfirmed) {
            thisRow.addClass('is-deleted');
        }
    })
});

// dashComponents

// init base functions
$(window).on('load', function () {
    scrollDirection();
    initGettersAndSetters();
});
$(window).on('hashchange', function (a) {
    initGettersAndSetters();
});

// js-
$('.js-clickableFalsyLink').on('click', function() {
    let target = $(this).data('targetlink');
    let link = $(this).data("href");
    if (target === '_blank') {
        window.open(link, '_blank');
    } else {
        window.location = link;
    }
});
if ($('.js-limitChars').length) {
    $('.js-limitChars').each(function() {
        limitCaracters($(this), 8);
    });
}

// l
if ($('.l-nav').length) {
    var navElement = $('.l-nav');
    var navMobile = $('.l-navHidden');
    var navHamburguer = $('.l-nav .c-hamburguer');

    const closeAllNavElements = () => {
        $('.c-subMenu').removeClass('is-active');
        navHamburguer.removeClass('is-active');
    }
    const disableOverflow = () => {
        $('body').addClass('u-overflowBlocked');
    }
    const enableOverflow = () => {
        $('body').removeClass('u-overflowBlocked');
    }

    // - open / close mobile menu
    $('.js-openNavMobile').on('click', function() {
        navMobile.addClass('is-active');
        disableOverflow();
    });
    $('.js-closeNavMobile').on('click', function() {
        navMobile.removeClass('is-active');
        //navHamburguer.removeClass('is-active');
        enableOverflow();
    });

    // scroll changes
    $(window).on('load', function(event) {
        var scrollBody = $(this).scrollTop();
        if (scrollBody > 99) {
            navElement.addClass('is-scrolled');
        } else {
            navElement.removeClass('is-scrolled');
        }
    });
    $(window).on('scroll', function() {
        var scrollBody = $(this).scrollTop();
        // scroll up to 99
        if (scrollBody > 99) {
            navElement.addClass('is-scrolled');
        } else {
            navElement.removeClass('is-scrolled');
        }
        // middle class
        if (scrollBody > 600) {
            navElement.addClass('is-hidden');
            navElement.addClass('is-middle');
        } else {
            navElement.removeClass('is-hidden');
            navElement.removeClass('is-middle');
        }
        // scroll up or down
        if (scrollBody < 0) {
            navElement.removeClass('is-hidden');
            navElement.addClass('is-scrolledUp');
            navElement.removeClass('is-scrolledDown');
        } else {
            navElement.removeClass('is-scrolledUp');
            navElement.addClass('is-scrolledDown');
        }
        // close menus on hidden nav
        if(navElement.hasClass('is-hidden')) {
            closeAllNavElements();
        }
        // reference var
        navElement.scrollInit = scrollBody;
    });
}

// s-
// https://sortablejs.github.io/Sortable/
if ($('.s-heroCols').length) {
    // sortable // drag // rows
    new Sortable(document.getElementById('sortableGridHero'), {
        handle: '.js-sortableGridHeroHandle', // handle class
        animation: 150,
    });

    // sortable // drag // cards inside rows
    let sortableCardsList = [];
    $('.js-sortableCards').each(function(index) {
        $(this).attr('id');
        sortableCardsList.push($(this).attr('id'));
    });
    for (var i = 0; i < sortableCardsList.length; i++) {
        //console.log(document.getElementById(sortableCardsList[i]))
        let handleAddClass = $('#' + sortableCardsList[i]).find('.js-sortableCardHandle').addClass('js-sortableCardHandle__' + i);
        let handleSelector = '.js-sortableCardHandle__' + i;
        //console.log(handleSelector);
        new Sortable(document.getElementById(sortableCardsList[i]), {
            animation: 150,
            ghostClass: 'blue-background-class',
            handle: handleSelector,
        });
    }
}

